let swiper;
$(document).ready(function() {
  let swiper = new Swiper(".swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    breakpoints: {
      767: {
        spaceBetween: 10,
        freeMode: true,
        slidesPerView: 3,
        allowTouchMove: true,
        scrollbar: {
          el: ".scroll-swiper-scrollbar",
          hide: true
        }
      }
    }
  });
});

jQuery(document).ready(function() {
  $("a.scrollLink").click(function(event) {
    event.preventDefault();
    $("html, body").animate(
      { scrollTop: $($(this).attr("href")).offset().top },
      500
    );
  });
});
